$('document').ready(function () {
    domain = window.location.host.split('.')[0];

    segments = window.location.href.split('/');
    // Current action slug parameter
    var slug = segments.pop();
    // CURRENT DOSSIER REFERENCE VALUE
    var dossierRef = $('input:hidden[name*=dossier_ref]').val();
    // Current siret value defined by inputs change events..
    var currentSiretValue;

    if (isInvoiceStep()) {
        //alert('kakaoaoao');
        // INVOICE STEP INPUTS SELECTORS
        var invProInput = $('input:text[name*=INV_SIRET]'),
            invSubcontractorInput = $('input:text[name*=INV_SUBCONTRACTOR_SIRET]'),
            invMeoInput = $('input:radio[name*=INV_PRO_MOA]'),
            invSubContractorInputs = $('.inv-subcontractor-container');

        var invMeoInputVal = invMeoInput.val();

        // Hide inputs on page load according to current value
        if (!isInvoiceMoaInputEmpty()) {
            invMeoInputVal = invMeoInput.val();
            if (isSubContracting()) {
                showSubContractorInputs();
            } else {
                hideSubContractorInputs();
            }
        }

        // EVENTS
        invProInput.on('input', function () {
            if ($(this).val().length == 14) {
                currentSiretValue = $(this).val();
                getProData('inv-pro');
            }
        });

        invSubcontractorInput.on('input', function () {
            if ($(this).val().length == 14 && "extranet" == domain) {
                currentSiretValue = $(this).val();
                getArtisanData()
            }
        });

        // CONDITIONNAL DISPLAY OF SUBCONTRACTOR INPUTS ACCORDING TO MEO VALUE
        invMeoInput.on('change', function () {
            invMeoInputVal = $(this).val();
            if (invMeoInputVal > 0) {
                showSubContractorInputs();
            } else {
                hideSubContractorInputs();
            }
        });
    }

    if (isAshStep()) {
        // Basically it changes TR background color to gray
        shadeReadOnlyInputs();

        // ASH STEP INPUTS SELECTORS
        var ashProInput = $('input:text[name*=ASH_PRO_SIRET]'),
            ashMeoInput = $('input:radio[name*=ASH_PRO_MOA]:checked'),
            ashSubContractorInput = $('input:text[name*=ASH_PRO2_SIRET]'),
            ashSubContractorInputs = $('[data-subcontractor-input]'),
            ashMeoInputVal;

        // Hide inputs on page load according to current value
        if (!isAshMoaInputEmpty()) {
            ashMeoInputVal = ashMeoInput.val();

            if (isSubContracting()) {
                showSubContractorInputs();
            } else {
                hideSubContractorInputs();
            }
        }

        // EVENTS
        ashProInput.on('input', function () {
            if ($(this).val().isNumeric()) {
                if ($(this).val().length == 14 && "extranet" == domain) {
                    currentSiretValue = $(this).val();
                    getProData('ash-pro', null, true);
                }
            }
        });

        ashSubContractorInput.on('input', function () {
            if ($(this).val().length == 14 && "extranet" == domain) {
                currentSiretValue = $(this).val();
                getArtisanData();
            }
        });

        // CONDITIONNAL DISPLAY OF SUBCONTRACTOR INPUTS ACCORDING TO MEO VALUE
        ashMeoInput.on('change', function () {
            ashMeoInputVal = $(this).val();
            if (ashMeoInputVal == 1) {
                hideSubContractorInputs();
            } else {
                showSubContractorInputs();
            }
        });

        $('.custom-radio').on('click', function (e) {
            if (e.target.hasAttribute('readonly')) {
                return false;
            }
        });

        if (!isSiretInputEmpty()) {
            currentSiretValue = ashProInput.val();
            // Basically we're supposed to have missing infos
            // only when we load ash page for the first time
            // because pro's firstname/lastname/function only sits on this form step (ASH)
            // so we call api to prefill inputs if it hasnt be done yet
            if (getMissingInfos().length > 0 && "extranet" == domain) {
                getProData('ash-pro', true, false);
            }
        }
    }


    // FETCH DATA FROM OPENDATASOFT & BFM API
    function getArtisanData(type = 'inv-subcontractor') {
        $.ajax(Routing.generate('opendatasoft_get_artisan_data', {
            siret: currentSiretValue
        })).statusCode({
            400: function (data) {
                showAlertBox("Un problème est survenu", data.responseJSON.message)
            },
            200: function (data) {
                showPreFillConfirmBox(data, type);
            }
        });
    }

    // FETCH DATA FROM OPENDATASOFT & BFM API
    function getProData(type, prefillMissingInputs = null, withDocument = false) {
        $.ajax(Routing.generate('opendatasoft_get_pro_data', {
            siret: currentSiretValue,
            prefill_missing_inputs: prefillMissingInputs
        })).statusCode({
            400: function (data) {
                showAlertBox("Un problème est survenu", data.responseJSON.message)
            },
            200: function (data) {
                showPreFillConfirmBox(data, type, withDocument);
            }
        })
    }

    function showAlertBox(title, message) {
        $.alert({
            title: title,
            content: message
        });
    }

    function showPreFillConfirmBox(data, type, withDocument = true, message = "Pré-remplir les informations") {
        $.confirm({
            title: message,
            content: data.message,
            type: 'red',
            buttons: {
                ok: {
                    text: "Confirmer",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                        if (type == 'inv-subcontractor') {
                            fillAshArtisanData(data.pro_data)
                        } else if (type == 'ash-pro') {
                            fillAshProData(data.pro_data);
                        } else if (type == 'inv-pro') {
                            fillInvoiceProData(data.pro_data)
                        }

                        /*if (isInvoiceStep()) {
                            setTimeout(uploadAndAttachInseeDoc(), 1500);
                        }*/
                    }
                },
                cancel: {
                    action: function () {
                        return;
                    }
                }
            }
        });
    }

    // Async call to upload & attach Insee document to dossier (AVIS DE SITUATION)
    function uploadAndAttachInseeDoc() {
        $.ajax(Routing.generate('api_artisan_upload_attach_insee_doc', {
            siret: currentSiretValue,
            reference: dossierRef,
        }), {}).statusCode({
            400: function (data) {
                showAlertBox("Un problème est survenu", data.responseJSON.message)
            },
            200: function (data) {
                showAlertBox("Avis de situation", data.message);
                uploadAndAttachRgeCertificat();
            }
        });
    }

    // Async call to upload & attach RGE document to dossier (CERTIFICAT RGE)
    function uploadAndAttachRgeCertificat() {
        $.ajax(Routing.generate('api_artisan_upload_attach_rge_certificat', {
            siret: currentSiretValue,
            reference: dossierRef
        })).statusCode({
            400: function (data) {
                showAlertBox("Un problème est survenu", data.responseJSON.message)
            },
            200: function (data) {
                showAlertBox("Certificat RGE", data.message);
            }
        });
    }

    // Helpers
    function shadeReadOnlyInputs() {
        $("input:read-only").closest('tr').css('background-color', '#A9A9A9');
    }

    function isSiretInputEmpty() {
        return ashProInput.val().length == 0
    }

    function isAshMoaInputEmpty() {
        return ashMeoInput.val().length == 0;
    }

    function isInvoiceMoaInputEmpty() {
        return invMeoInput.val().length == 0;
    }

    function isAshStep() {
        return $.inArray(slug, ['ash', 'A', 'B', 'C', 'D']) > -1;
    }

    function isInvoiceStep() {
        return slug == 'facture';
    }

    function getMissingInfos() {
        missingInfos = [];

        proInfos = {
            'nom': $('input:text[name*=ASH_PRO_LAST_NAME]'),
            'prenom': $('input:text[name*=ASH_PRO_FIRST_NAME]'),
            'fonction': $('input:text[name*=ASH_PRO_FUNCTION]')
        };

        $.each(proInfos, function (name, input) {
            if (input.val().length == 0) {
                missingInfos.push(name)
            }
        });

        return missingInfos;
    }

    // functions used to fill inputs with api response's data

    /**
     * @param data
     */
    function fillInvoiceProData(data) {
        $('input:text[name*=INV_SOCIAL_NAME]').val(data.social_name);
        $('input:text[name*=INV_ADRESSE_PRO]').val(data.address);
        $('input:text[name*=INV_ZIPCODE_PRO]').val(data.zip_code);
        $('input:text[name*=INV_CITY_PRO]').val(data.city);
    }

    /**
     * @param data
     */
    function fillAshProData(data) {
        $('input:text[name*=ASH_PRO_LAST_NAME]').val(data.lastname);
        $('input:text[name*=ASH_PRO_FIRST_NAME]').val(data.firstname);
        $('input:text[name*=ASH_PRO_SOCIAL_NAME]').val(data.social_name);
        $('input:text[name*=ASH_PRO_ADDRESS]').val(data.address);
        $('input:text[name*=ASH_PRO_ZIPCODE]').val(data.zip_code);
        $('input:text[name*=ASH_PRO_CITY]').val(data.city);
        $('input:text[name*=ASH_PRO_FUNCTION]').val(data.job)
    }

    /**
     * @param data
     */
    function fillAshArtisanData(data) {
        $('input:text[name*=INV_SUBCONTRACTOR_LAST_NAME]').val(data.lastname);
        $('input:text[name*=INV_SUBCONTRACTOR_FIRST_NAME]').val(data.firstname);
        $('input:text[name*=INV_SUBCONTRACTOR_SOCIAL_NAME]').val(data.social_name);
    }

    function hideSubContractorInputs() {
        if (isAshStep()) {
            ashSubContractorInputs.addClass("hidden")
        } else {
            invSubContractorInputs.addClass("hidden");
        }
    }

    function showSubContractorInputs() {
        if (isInvoiceStep()) {
            invSubContractorInputs.removeClass("hidden");
        } else {
            ashSubContractorInputs.removeClass("hidden")
        }
    }

    // Not used at the moment but may be usefull in the future..
    function hasDocument(categoryId) {
        var exists = null;

        $.ajax(Routing.generate('api_artisan_check_for_existing_document', {
            reference: dossierRef,
            file_category: categoryId
        }), {async: false}).statusCode({
            409: function (data) {
                exists = true;
            },
            200: function (data) {
                exists = false;
            }
        });

        return exists;
    }

    function isSubContracting() {
        return ashMeoInputVal == 2 || invMeoInputVal == 1;
    }

});
