$('document').ready(function () {
    var newTagInput = null;
    var emailToInput = $('#email_contact_to');
    var emailCCInput = $('#email_contact_cc');

    var recipientTagsAsArray = emailToInput.val() ? emailToInput.val().split(',') : [];
    var assocRecipientTag = makeRecipientAssocArray();
    var assocCCTag = [];

    if (recipientTagsAsArray.length > -1) {
        initializeRecipientTags();
    }

    function makeRecipientAssocArray() {
        assoc = [];

        $.each(recipientTagsAsArray, function (key, v) {
            tmp = v.split('=');

            obj = {
                'title': tmp[0],
                'body': tmp[1]
            };

            assoc.push(obj);
        });

        return assoc;
    }

    function initializeRecipientTags() {
        $.each(assocRecipientTag, function (k, obj) {
            addTag(obj.body, obj.title, $("#recipient_tags.tags"), false,true);
        });

        appendNewTagInput();
    }

    function appendNewTagInput() {
        $('.tags').append("<li class='new-tag'><input class='new-tag-input' type='text'></li>");
        newTagInput = $('input:text[class*=new-tag-input]');
    }

    function addTag(value, title, targetList, prepend = false, onInit = false) {
        tag = "<li class='tag'>"
            + "<div class='tag-title'>" + title + "</div>"
            + "<div class='tag-body'>" + value + "<span class='remove-tag'>x</span></div>"
            + "</li>";

        if(onInit){
            tag = "<li data-locked=1 class='tag'>"
                + "<div class='tag-title'>" + title + "</div>"
                + "<div class='tag-body'>" + value + "<span class='remove-tag'>x</span></div>"
                + "</li>";
        }

        if (!prepend || targetList.length == 0) {
            targetList.append(tag);
        } else {
            targetList.find('.new-tag').before(tag);
        }

        if (targetList.attr('id') == "recipient_tags") {
            if (objectInArray(assocRecipientTag, value) == -1) {
                assocRecipientTag.push({'title': title, 'body': value})
            }
        }

        if (targetList.attr('id') == "cc_tags") {
            if (objectInArray(assocCCTag, value) == -1) {
                assocCCTag.push({'title': 'Email', 'body': value})
            }
        }

        refreshTags();
    }

    function objectInArray(array, email) {
        return array.map(function (e) {
            return e.body;
        }).indexOf(email)
    }

    function removeTag(element) {
        targetList = element.parent().closest('ul');

        addressToRemove = element.contents().filter(function () {
            return this.nodeType == Node.TEXT_NODE;
        }).text();

        if (targetList.attr('id') == "recipient_tags") {
            if (objectInArray(assocRecipientTag, addressToRemove) > -1) {
                element.parent().remove();

                assocRecipientTag = $.grep(assocRecipientTag, function (e) {
                    return e.body != addressToRemove;
                });
            }
        }

        if (targetList.attr('id') == "cc_tags") {
            if (objectInArray(assocCCTag, addressToRemove) > -1) {
                element.parent().remove();

                assocCCTag = $.grep(assocCCTag, function (e) {
                    return e.body != addressToRemove;
                });
            }
        }

        // Refresh input values
        refreshTags();
    }

    // refresh inputs values after each item insertion/removal
    function refreshTags() {
        emailToInput.val(getRecipientEmailsArray().join(','));
        emailCCInput.val(getCCEmailsArray().join(','));
    }

    function getRecipientEmailsArray() {
        emailsArray = [];

        $.each(assocRecipientTag, function (k, e) {
            emailsArray.push(e.body);
        });

        return emailsArray;
    }

    function getCCEmailsArray() {
        emailsArray = [];

        $.each(assocCCTag, function (k, e) {
            emailsArray.push(e.body);
        });

        return emailsArray;
    }

    // Add tag on keypress if tags does not already exists..
    newTagInput.on('keypress', function (e) {
        targetList = $(this).parent().parent();

        if (e.which == 13) {
            e.preventDefault();
            newTagValue = $(this).val();

            if (newTagValue.length > 5) {
                if ($(".tags:contains('" + newTagValue + "')").length == 0) {
                    addTag(newTagValue, 'Email', targetList, true);
                }
            }

            newTagInput.val('');
        }
    });

    $('.tags').click(function () {
        $(this).find('input').focus();
    });

    $(document).on('click', '.remove-tag', function () {
        removeTag($(this).parent());
    });
});