$('document').ready(function () {

    var remuType = $('#commercial_conditions_remunerationType');
    var leadPrice = $('#commercial_conditions_leadPrice').closest('.field-container');

    var kwhPriceField = $('.commercial_conditions_prices_container').find('input[name*=kwhPrice0]');
    var kwhPrecaField1 = $('.commercial_conditions_prices_container').find('input[name*=kwhPrice1]');
    var kwhPrecaField2 = $('.commercial_conditions_prices_container').find('input[name*=kwhPrice2]');
    var parentRemuField = $('#commercial_conditions_parentKwhRemuneration');
    var partnerRemuField = $('#commercial_conditions_kwhRemuneration');
    var comFields = $('.commissionField');
    var envField1 = $('#commercial_conditions_enveloppe');
    var envField2 = $('#commercial_conditions_enveloppe2');
    var envField3 = $('#commercial_conditions_enveloppe3');

    leadPrice.hide();
    //

    remuType.change(function () {
        if ($(this).val() == 0) {
            comFields.closest('.field-container').show();
            leadPrice.hide();
        } else {
            comFields.closest('.field-container').hide();
            leadPrice.show();
        }
    }).change();

    comFields.keyup(function () {
        var kwhPrice = kwhPriceField.val();
        var kwhPreca1 = kwhPrecaField1.val();
        var kwhPreca2 = kwhPrecaField2.val();
        var parentRemu = parentRemuField.val();
        var partnerRemu = partnerRemuField.val();

        kwhPrice = parseFloat(kwhPrice ? kwhPrice.replace(",", ".") : 0);
        kwhPreca1 = parseFloat(kwhPreca1 ? kwhPreca1.replace(",", ".") : 0);
        kwhPreca2 = parseFloat(kwhPreca2 ? kwhPreca2.replace(",", ".") : 0);
        parentRemu = parseFloat(parentRemu ? parentRemu.replace(",", ".") : 0);
        partnerRemu = parseFloat(partnerRemu ? partnerRemu.replace(",", ".") : 0);

        var globalEnv = kwhPrice + partnerRemu + parentRemu;
        var precaEnv = kwhPreca1 + partnerRemu + parentRemu;
        var precaEnv2 = kwhPreca2 + partnerRemu + parentRemu;

        envField1.val(globalEnv.toFixed(5));

        if (kwhPreca1 != '') {
            envField2.val(precaEnv.toFixed(5));
        } else {
            envField2.val('Enveloppe de site correspondant')
        }

        if (kwhPreca2 != '') {
            envField3.val(precaEnv2.toFixed(5));
        } else {
            envField3.val('Enveloppe de site correspondant')
        }

    }).keyup();

});


