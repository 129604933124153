import MicroModal from "micromodal";

$('document').ready(function () {
    var uploadTrigger = $('#siren-csv-trigger');
    var confirmBtn = $('#siren-csv-confirm');
    var fileInput = $('[data-siren-file]');
    var verifOkContainer = $('#siren-verif-ok');
    var verifNokContainer = $('#siren-verif-nok');
    var form = $('form[name="siren_verification"]');
    var fileNameHolder;
    var featureContainer = $('#feature-container');

    uploadTrigger.on('click', function () {
        fileInput.click();
    });

    fileInput.on('change', function () {
        var fileName = $(this).val().split('\\').pop();

        appendFileName(fileName);

        uploadTrigger.before(fileNameHolder);

        uploadTrigger.addClass('hidden');

        confirmBtn.removeClass('hidden');
    });

    confirmBtn.on('click', function () {
        form.submit();
    });

    form.on('submit', function (e) {
        e.preventDefault();

        $.ajax({
            url: Routing.generate('extranet_features_siren_verification'),
            type: 'post',
            data: new FormData(form[0]),
            cache: false,
            contentType: false,
            processData: false,
        }).statusCode({
            500: function (response) {
                alert('Fichier invalide.');
            },
            200: function (response) {
                processData(response.siren_list);
            }
        });
    });

    function displayLoadingOverlay() {
        featureContainer.LoadingOverlay("show", {imageColor: "#1b284f"});
    }

    function hideLoadingOverlay() {
        featureContainer.LoadingOverlay("hide");
    }

    function processData(sirenList) {
        sirenList = Object.values(sirenList);

        displayLoadingOverlay();

        let count = sirenList.length;
        let i = 0;

        $.each(sirenList, function (index, value) {
            if (value.length !== "") {
                $.ajax(Routing.generate('opendatasoft_get_pro_data', {
                    siret: value,
                    siren_verif: true
                })).statusCode({
                    400: function (data) {
                        verifNokContainer.find('ul').append("<li class='control-list-item'>" + data.responseJSON.message + "</li>");
                        ++i;

                        if (i == count) {
                            hideLoadingOverlay();
                        }
                    },
                    200: function (data) {
                        verifOkContainer.find('ul').append("<li class='control-list-item'>" + data.message + "</li>");
                        ++i;

                        if (i == count) {
                            hideLoadingOverlay();
                        }
                    }
                });
            }
        });
    }

    function appendFileName(fileName) {
        let removeIcon = $('<a href="#" id="remove-csv-file"><i class="fa fa-times fa-fw"></i></a>');

        removeIcon.find('i').css('font-size', '12px');

        fileNameHolder = $('<p class="text-center bold" id="csv-filename"></p>');
        fileNameHolder.text("");
        fileNameHolder.text(fileName);
        fileNameHolder.append(removeIcon);
    }

    $(document).on('click', '#remove-csv-file', function () {
        fileInput.val(null);
        fileNameHolder.remove();
        confirmBtn.addClass('hidden');
        uploadTrigger.removeClass('hidden');
    });
});


