$('document').ready(function () {

        var dossier_ref = $('#dossier_reference').val(),

            emailToBtn = $('#email-to-btn'),
            emailToPopup = $('#email-to-popup'),
            emailToInput = $('#email_contact_to'),
            emailCcBtn = $('#email-cc-btn'),
            emailCcPopup = $('#email-cc-popup'),
            emailCcInput = $('#email_contact_cc'),
            emailAction = $('#email_contact_action'),
            status = $('#email_contact_status'),
            callHistoryBtn = $('#call-history-btn'),
            partnerCallHistoryBtn = $('#partner-call-history-btn'),

            timerContainer = $('#call-history-timer'),
            partnerTimerContainer = $('#partner-call-history-timer'),
            timerInput = $('#call_history_time'),
            partnerTimerInput = $('#partner_call_history_time');

        var currentPartner = $('#partner_id').val();

        var addressesRoute;

        if (window.location.href.search("gestion-des-partenaires") !== -1) {
            addressesRoute = 'api_email_load_partner_emails';
            param = currentPartner;

        } else {
            addressesRoute = 'api_email_load_beneficiary_list';
            param = dossier_ref;
        }

        $('.type-btn').click(function (e) {
            $('.pressed').removeClass('pressed');
            $(e.target).toggleClass('pressed');

            type = $(this).attr('id');

            if ($.inArray(type, ['incomplete', 'refused', 'complete']) > -1) {
                status.val(type);
            }

            $.ajax(Routing.generate('api_email_dossier_template_type', {
                dossier_ref: dossier_ref,
                type: type
            })).done(function (template) {
                CKEDITOR.instances.email_contact_body.setData(template);
                emailAction.val(type)
            });

            $.ajax(Routing.generate('api_email_dossier_object_type', {
                dossier_ref: dossier_ref,
                type: type
            })).done(function (object) {
                $('#email_contact_object').val(object);
            });
            return false;
        });

        $('#basic').click();

        function showAlertBox(title, message) {
            $.alert({
                title: title,
                content: message
            });
        }

        $('.partner-type-btn').click(function (e) {
            $('.pressed').removeClass('pressed');
            $(e.target).toggleClass('pressed');

            type = $(this).attr('id');

            $.ajax(Routing.generate('api_email_partner_template_type', {
                partner_id: currentPartner,
                type: type
            })).statusCode({
                500: function (data) {
                    showAlertBox("Un problème est survenu", 'Une erreur interne empèche la récupération du template demandé, merci de remonter le problème.')
                },
                400: function (data) {
                    showAlertBox("Un problème est survenu", data.responseJSON.message)
                },
                200: function (template) {
                    CKEDITOR.instances.email_contact_body.setData(template);
                    emailAction.val(type)
                }
            });

            $.ajax(Routing.generate('api_email_partner_object_type', {
                type: type
            })).done(function (object) {
                $('#email_contact_object').val(object);
            });
            return false;
        });

    $('#partner-basic').click();

        emailToBtn.on('click', function () {
            $.ajax(Routing.generate(addressesRoute, {
                param: param,
            })).done(function (beneficiaries) {
                emailToPopup.empty();

                $.each(beneficiaries, function (key, value) {
                    emailToPopup.append('<div class="row" style="border-bottom: 1px solid black">' + key.toUpperCase() + ' : <a href="#" class="popup-list-item" style="color:#073c67">' + value + '</a></div><br>');
                });

                emailToPopup.dialog({
                    width: 380,
                    position: {my: "center", at: "right+100 top", of: '#mailer-editor'}
                })
            });
            return false;
        });

        emailCcBtn.on('click', function () {
            $.ajax(Routing.generate(addressesRoute, {
                param: param,
            })).done(function (beneficiaries) {
                emailCcPopup.empty();

                $.each(beneficiaries, function (key, value) {
                    emailCcPopup.append('<div class="row" style="border-bottom: 1px solid black">' + key.toUpperCase() + ' : <a href="#" class="popup-list-item" style="color:#073c67">' + value + '</a></div><br>');
                });

                emailCcPopup.dialog({
                    width: 380,
                    position: {my: "center", at: "right+100 top+200", of: '#mailer-editor'}
                })
            });
            return false;
        });

        callHistoryBtn.on('click', function () {
            return false;
        });

        partnerCallHistoryBtn.on('click', function () {
            return false;
        });

        $(document).on('click', '.popup-list-item', function (e) {

            var parent = $(e.target).parent().parent();

            if (emailToPopup.attr('id') === parent.attr('id')) {
                emailToInput.empty();
                emailToInput.val($(this).html());
                emailToPopup.dialog('close');
            } else {
                emailCcInput.empty();
                emailCcInput.val($(this).html());
                emailCcPopup.dialog('close');
            }
        });


        function addCallHistoryEntry() {
            timerInput.val(timerContainer.data('seconds'));
            $.ajax({
                url: Routing.generate('extranet_call_history_historize'),
                type: 'post',
                data: form.serializeArray(),
                success: function (response) {
                    timerContainer.timer('remove');
                    dialog.dialog('close')
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        function addPartnerCallHistoryEntry() {
            partnerTimerInput.val(partnerTimerContainer.data('seconds'));
            $.ajax({
                url: Routing.generate('extranet_partner_call_history_historize'),
                type: 'post',
                data: partnerForm.serializeArray(),
                success: function (response) {
                    partnerTimerContainer.timer('remove');
                    partnerDialog.dialog('close')
                    location.reload();
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        dialog = $("#history-popup").dialog({
            autoOpen: false,
            height: 790,
            width: 950,
            modal: true,
            buttons: {
                "Historiser": addCallHistoryEntry,
                Fermer: function () {
                    dialog.dialog("close");
                }
            },
            close: function () {
                form[0].reset();
                timerContainer.timer('remove')
            }
        });

        partnerDialog = $("#partner-history-popup").dialog({
            autoOpen: false,
            height: 550,
            width: 950,
            modal: true,
            buttons: {
                "Historiser": addPartnerCallHistoryEntry,
                Fermer: function () {
                    partnerDialog.dialog("close");
                }
            },
            close: function () {
                partnerForm[0].reset();
                partnerTimerContainer.timer('remove')
            }
        });

        form = dialog.find("form").on("submit", function (event) {
            event.preventDefault();
            addCallHistoryEntry()
        });

        partnerForm = partnerDialog.find("form").on("submit", function (event) {
            event.preventDefault();
            addPartnerCallHistoryEntry()
        });

        callHistoryBtn.button().on("click", function () {
            dialog.dialog("open");
            callTimer();
        });

        partnerCallHistoryBtn.button().on("click", function () {
            partnerDialog.dialog("open");
            partnerCallTimer();
        });

        function callTimer() {
            timerContainer.timer()
        }

        function partnerCallTimer() {
            partnerTimerContainer.timer()
        }
    }
);