$('document').ready(function () {
    var tableScroller = {
        tables: $('.table-carousel'),

        init: function () {
            var self = this;

            self.tables.each(function (i, e) {
                var tableLeftContainer = $(e).find('.table-left');
                var tableRightContainer = $(e).find('.table-right');
                var maxHeight = Math.max(tableLeftContainer.height(), tableRightContainer.height());
                tableLeftContainer.add(tableRightContainer).css('height', maxHeight + 'px');

                var tableDataColNumber = $(e).find('.table-carousel-wrapper th').length;
                var index = -(tableDataColNumber - 3);
                var tableDataColWidth = Math.ceil($(e).find('.table-carousel-wrapper').width() / 3);
                var tableDataWidth = tableDataColWidth * tableDataColNumber;

                if (tableDataColNumber >= 3) {
                    $(e).find('.table-carousel-wrapper th').css({'width': tableDataColWidth});
                    $(e).find('.table-carousel-wrapper table').css({
                        'width': tableDataWidth,
                        "left": index * tableDataColWidth
                    }).data('index', index);
                } else {
                    $(e).find('.table-carousel-wrapper th').css({'width': tableDataColWidth});
                    $(e).find('.table-carousel-wrapper table').css({'width': Math.ceil($(e).find('.table-carousel-wrapper').width())}).data('index', index);
                }
                $(e).data('index', index);
            });

            self.tables.find('.prev').on('click', function (e) {
                e.preventDefault();
                self.goPrev($(this).closest('.table-carousel'));
            });

            self.tables.find('.next').on('click', function (e) {
                e.preventDefault();
                self.goNext($(this).closest('.table-carousel'));
            });
        },
        goPrev: function (table) {
            var self = this;
            var index = table.data('index');
            if (index < 0) {
                var target = index + 1;
                table.data('index', target);
                self.goTo(table, target);
            }
        },
        goNext: function (table) {
            var self = this;
            var index = table.data('index');
            if (index > -(table.find('.table-carousel-wrapper th').length - 3)) {
                var target = index - 1;
                table.data('index', target);
                self.goTo(table, target);
            }
        },
        goTo: function (table, target) {
            var self = this;
            var left = target * Math.ceil(table.find('.table-carousel-wrapper').width() / 3)
            table.find('.table-carousel-wrapper table').stop(false, true).animate({'left': left}, 400);
        }
    };

    tableScroller.init();
});