import {VteNotify} from "../../Utility/customNotification";

$('document').ready(function () {


    let button;

    $('.ladda-button').click(function(e){
        button = this;
    });

    $(document).ajaxStart(function() {
        $(".ladda-button").attr("disabled", true);
    }).ajaxComplete(function() {
        $(".ladda-button").removeAttr("disabled");
    });

    $(function() {
        $('.aaf_form').submit(function(e){
            e.preventDefault();

            let l = Ladda.create(button);
            l.start();

            let form = $(this);

            let data = {};
            $(this).serializeArray().forEach((object)=>{
                data[object.name] = object.value;
            });

            $.ajax({
                url: Routing.generate('extranet_paiement_aaf_create'),
                type: 'post',
                dataType: "JSON",
                data: data,
                async: true,
                success:function(data){

                }
            }).statusCode({
                400: function (data) {
                    VteNotify('Échec', data, 'error');
                },
                200: function (data) {
                    VteNotify('Succès', data, 'success');
                    button.remove();
                    form.next("span").removeClass('hidden');
                    form.parent('td').prev().html('paid');
                }
            }).always(function() { l.stop(); });
            return false;
        });
    });

    $(function() {
        $('.aaf_com_form').submit(function(e){
            e.preventDefault();

            let l = Ladda.create(button);
            l.start();

            let form = $(this);

            let data = {};
            $(this).serializeArray().forEach((object)=>{
                data[object.name] = object.value;
            });

            $.ajax({
                url: Routing.generate('extranet_paiement_aaf_com_create'),
                type: 'post',
                dataType: "JSON",
                data: data,
                async: true,
                success:function(data){

                }
            }).statusCode({
                400: function (data) {
                    VteNotify('Échec', data, 'error');
                },
                200: function (data) {
                    VteNotify('Succès', data, 'success');
                    button.remove();
                    form.next("span").removeClass('hidden');
                    form.parent('td').prev().html('paid');
                }
            }).always(function() { l.stop(); });
            return false;
        });
    });

    $(function() {
        $('.aaf_mpr_form').submit(function(e){
            e.preventDefault();

            let l = Ladda.create(button);
            l.start();

            let form = $(this);

            let data = {};
            $(this).serializeArray().forEach((object)=>{
                data[object.name] = object.value;
            });

            $.ajax({
                url: Routing.generate('extranet_paiement_aaf_mpr_create'),
                type: 'post',
                dataType: "JSON",
                data: data,
                async: true,
                success:function(data){

                }
            }).statusCode({
                400: function (data) {
                    VteNotify('Échec', data, 'error');
                },
                200: function (data) {
                    VteNotify('Succès', data, 'success');
                    button.remove();
                    form.next("span").removeClass('hidden');
                    form.parent('td').prev().html('paid');
                }
            }).always(function() { l.stop(); });
            return false;
        });
    });

});



