//Core
import 'Core/main';

//Extranet SCSS
import 'scss/Extranet/app.scss';

//Extranet JS
import "Extranet/Partner/partner"
import "Extranet/Artisan/artisan"
import "Extranet/Paiement/paiement"
import "Extranet/Depot/depot.js"
import "Extranet/Contact/contact"
import "Extranet/Contact/contact_tags"
import "Extranet/Stats/stats"
import "Extranet/Features/siren_verif"
import "Extranet/Dossier/link-gestionnaire"

//Vte Tools
import "vteTools/reportingtable"
import "vteTools/envelopComputing"
import "vteTools/precarity-select"
import "vteTools/Simulation/global_simulator"
import "vteTools/tooltipsVte"