import MicroModal from "micromodal";

$('document').ready(function () {

    var siretInput = $('#siren_api_siret'),
        companyNameInput = $('#siren_api_company_name'),
        zipcodeInput = $('#siren_api_zipcode'),
        addressInput = $('#siren_api_address'),
        cityInput = $('#siren_api_city'),
        userFirstNameInput = $('#siren_api_contact_firstname'),
        userLastNameInput = $('#siren_api_contact_lastname'),
        civilityInput = $('#siren_api_contact_profile_civility'),
        corporateNameInput = $('#siren_api_contact_profile_corporate_name');

    var documentType = $('#commercial_conditions_file_type');

    var dialog = $('#add-partner-modal');

    var form = dialog.find("form").on("submit", function (event) {
        event.preventDefault();
    });

    $("#create-partner").on("click", function () {
        if (dialog.length > 0) {
            MicroModal.show("add-partner-modal")
        }
        return false;
    });

    function addPartner() {
        $.ajax({
            url: Routing.generate('extranet_partner_new'),
            type: 'post',
            datatype: 'json',
            data: form.serializeArray(),
            success: function (response) {
                MicroModal.close('add-partner-modal');

                if (response.redirect_path) {
                    window.location.href = response.redirect_path;
                }
            },
            error: function (error) {
                console.log(error);
            }
        });
    };

    // Ici on build le formulaire d'inscription avec les data retournées par l'API
    siretInput.on('input', function () {
        if ($(this).val().length == 14) {
            $.ajax(Routing.generate('opendatasoft_get_data', {siret: $(this).val()}))
                .done(function (data) {
                    if (data) {
                        showPreFillConfirmBox(data);
                    }
                });
        }
    });

    function showPreFillConfirmBox(data) {

        let companyName = data.social_name.replace(/[*/']/g, ' ');

        $.confirm({
            title: "Confirmation",
            content: "Souhaitez vous pré-remplir les informations de l'entreprise " + companyName + " ?",
            type: 'red',
            buttons: {
                Oui: {
                    text: "Oui",
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action: function () {
                        companyNameInput.val(companyName);
                        zipcodeInput.val(data.zip_code);
                        cityInput.val(data.libellecommuneetablissement);
                        addressInput.val(data.address);
                        userFirstNameInput.val(data.firstname);
                        userLastNameInput.val(data.lastname);
                        corporateNameInput.val(data.companyName);
                        siretInput.val(data.siret);

                        addPartner();
                    }
                },
                Non: {
                    text: "Non",
                    action: function () {
                        return;
                    }
                }
            }
        });
    }

    // ON CACHE LE BOUTON d'UPLOAD TANT QU'AUCUN TYPE DE FICHIER N'EST SELECTIONNE
    documentType.on('change', function () {
        if ($(this).val() != 'rge') {
            $('#eligible').hide();
        } else {
            $('#eligible').show();
        }
        if ($(this).val() == 0) {
            $('.uploadInput').hide();
        } else {
            $('.uploadInput').show();
        }
    }).change();

});