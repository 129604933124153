import {VteNotify} from "../../Utility/customNotification";

$('document').ready(function () {

    //Depot
    var referenceInput = $('#dossier_scan_reference'),
        dossierScanScanned = $('#dossier_scan_scanned')

    //Ladda
    var button;

    $('.ladda-button').click(function(e){
        button = this;
    });

    $(document).ajaxStart(function() {
        $(".ladda-button").attr("disabled", true);
    }).ajaxComplete(function() {
        $(".ladda-button").removeAttr("disabled");
    });


    //ScanTrigger
    referenceInput.change(function () {

        if (referenceInput.val().length >= 6) {
            dossierScan();
        }
    });

    //Scan
    dossierScanScanned.change(function () {

        if (referenceInput.val().length >= 6) {
            dossierScan();
        }
    });

    //Scan
    function dossierScan() {
        let form = $('#scan_dossier_form');

        let data = {};
        form.serializeArray().forEach((object)=>{
            data[object.name] = object.value;
        });

        $.ajax({
            url: Routing.generate('depot_update_scan_status'),
            type: 'post',
            dataType: "JSON",
            data: data,
            async: true,
        }).statusCode({
            400: function (data) {
                let i = $("#missing_dossier_count").text();
                let c = parseInt(i);
                c++;
                $("#missing_dossier_count").text(c);
                let reference = data.responseJSON.reference;
                let name = data.responseJSON.name;
                let created_at = data.responseJSON.incitation_date;
                let address = data.responseJSON.work_address;
                let partner = data.responseJSON.partner;
                let kwh = data.responseJSON.kwh_amount;
                let prime = data.responseJSON.prime_amount;
                let scan = data.responseJSON.scan;
                let markup = "<tr style=\"background-color:#FA8072\">" +
                    "<td>" + reference + "</td>" +
                    "<td>" + name + "</td>" +
                    "<td>" + created_at + "</td>" +
                    "<td>" + address + "</td>" +
                    "<td>" + partner + "</td>" +
                    "<td>" + kwh + "</td>" +
                    "<td>" + prime + "</td>" +
                    "<td>" + scan + "</td>" +
                    "</tr>";
                $("table tbody").append(markup);
                },
            200: function (data) {
                let i = $("#missing_dossier_count").text();
                let c = parseInt(i);
                c++;
                $("#missing_dossier_count").text(c);
                let reference = data.reference;
                let name = data.name;
                let created_at = data.incitation_date;
                let address = data.work_address;
                let partner = data.partner;
                let kwh = data.kwh_amount;
                let prime = data.prime_amount;
                let scan = data.scan;
                let markup = "<tr style=\"background-color:#ADFF2F\">" +
                    "<td>" + reference + "</td>" +
                    "<td>" + name + "</td>" +
                    "<td>" + created_at + "</td>" +
                    "<td>" + address + "</td>" +
                    "<td>" + partner + "</td>" +
                    "<td>" + kwh + "</td>" +
                    "<td>" + prime + "</td>" +
                    "<td>" + scan + "</td>" +
                    "</tr>";
                $("table tbody").append(markup);
            }
        })

    }

    //Add exclude dossier
    $(function() {
        $('#include_form').submit(function(e){
            e.preventDefault();

            let l = Ladda.create(button);
            l.start();

            let data = {};
            $(this).serializeArray().forEach((object)=>{
                data[object.name] = object.value;
            });

            $.ajax({
                url: Routing.generate('depot_include_in_ongoing_volume'),
                type: 'post',
                dataType: "JSON",
                data: data,
                async: true,
                success:function(data){

                }
            }).statusCode({
                400: function (data) {
                    VteNotify('Erreur', data, 'error');
                },
                200: function (data) {
                    $.each(data.errors, function( key, value ) {
                        VteNotify('Échec', value, 'error');
                    });
                    $.each(data.includeds, function( key, value ) {
                        VteNotify('Succès', 'Exclusion retirée : ' + value, 'success');
                        $(".row_for_ref_" + value).remove();
                    });
                    if ($("#tableRecap").find('tbody').find('tr').length <= 0) {
                        location.reload();
                    }
                }
            }).always(function() {
                l.stop();
            });
            return false;
        });
    });

    //
    $('#assemblage_filter_submit').click(function (e) {
        $('#assemblage_filter_form').submit();
    });


    $('#add_dossiers_submit').click(function (e) {
        addDossiersDepot();
    });

    $('#remove_dossiers_submit').click(function (e) {
        removeDossiersDepot();
    });



    function addDossiersDepot() {


        let l = Ladda.create(button);
        l.start();

        let form = $('#add_dossiers_form');

        let data = {};
        form.serializeArray().forEach((object)=>{
            data[object.name] = object.value;
        });

        $.ajax({
            url: Routing.generate('extranet_depot_add_many_dossiers'),
            type: 'post',
            dataType: "JSON",
            data: data,
            async: true,
        }).statusCode({
            400: function (data) {
                VteNotify('Échec', data.responseJSON, 'danger');
            },
            200: function (data) {
                $.each(data.success, function( key, value ) {
                    VteNotify('Succès', value, 'success');
                });

                $.each(data.error, function( key, value ) {
                    VteNotify('Erreur', value, 'danger');
                });
            }
        }).always(function() {
            l.stop();
        });

    }

    function removeDossiersDepot() {


        let l = Ladda.create(button);
        l.start();

        let form = $('#remove_dossiers_form');

        let data = {};
        form.serializeArray().forEach((object)=>{
            data[object.name] = object.value;
        });

        $.ajax({
            url: Routing.generate('extranet_depot_exclude_many_dossiers'),
            type: 'post',
            dataType: "JSON",
            data: data,
            async: true,
        }).statusCode({
            400: function (data) {
                VteNotify('Échec', data.responseJSON, 'danger');
            },
            200: function (data) {
                $.each(data.success, function( key, value ) {
                    VteNotify('Succès', value, 'success');
                });

                $.each(data.error, function( key, value ) {
                    VteNotify('Erreur', value, 'danger');
                });
            }
        }).always(function() {
            l.stop();
        });

    }



});